import "./ProductDescription.css";
import React, { useEffect } from "react";
import { BsBank2, BsClipboard2CheckFill, BsFillStarFill } from "react-icons/bs";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { CgCheck, CgRepeat } from "react-icons/cg";
import { RiBus2Line, RiBusLine, RiCheckLine, RiRepeat2Line, RiRidingFill, RiRidingLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../../../../utils/index.js";
import { Reviews } from "../Reviews/Reviews.jsx";
import { MoreInfo } from "../MoreInfo/MoreInfo.jsx";

export const ProductDescription = ({ selectedProduct }) => {
  const {
    addToCartHandler,
    wishlistHandler,
    isProductInCart,
    isProductInWishlist,
    cartLoading,
    setActiveSize,
    activeSize
  } = useUserData();

  useEffect(() => {
    window.fbq('track', 'ViewContent', {
      content_ids: [selectedProduct?.slug], // 'REQUIRED': array of product IDs
      content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
    });
    return () => {
      setActiveSize('')
    }
    
  }, [])

  const sendWhatsAppMessage = () => {
    const phoneNumber = '+2348039938596'; // Replace with the recipient's phone number, including country code
    const message = "Hello, I'm interested in learning more about the product - " + selectedProduct?.title + ` (https://360gadgetsafrica.com/product-details/${selectedProduct?.slug})`
    
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}` 
    // Check if WhatsApp is installed
    window.open(url,"__blank")
  }

  return (
    <div className="product-details-description">
      <h1 className="product-name">{selectedProduct?.title}</h1>

      <div className="ratings-reviews">
        <span></span>
        <span>{selectedProduct?.rating["$numberDecimal"]}</span>{" "}
        <BsFillStarFill color={"orange"} />
        <span>
          <span className="review">({selectedProduct?.views}) views</span>
        </span>
      </div>

      <div className="product-price-container">
        <span className="product-original-price">
          ₦{numberWithCommas(selectedProduct?.original_price)}{" "}
        </span>
        <span className="product-discount-price">
          {" "}
          ₦{numberWithCommas(selectedProduct?.discounted_price)}
        </span>
      </div>

   

      {/* <span className="gender-container">
        <span>Genre</span>: {selectedProduct?.categoryId.title}
      </span> */}
      {selectedProduct.size?.length > 0 && <div className="description-container">
        <div className="xbold"> Size </div> <div>
          {selectedProduct.size.map(item => (
            <span onClick={() => (selectedProduct.is_stock && item.is_stock) && setActiveSize(item.title)} className={`select-phone ${item.title == activeSize ? 'active' : ''} ${!item.is_stock || !selectedProduct.is_stock ? 'x-disable' : ""}`}>{item.title}</span>
          ))}
        </div>
      </div>}

      <div className="tags">
        {selectedProduct?.is_stock ?
          <span className="in-stock">
            {selectedProduct.is_stock} in Stock
          </span> :
          <span className="out-of-stock">
            Out of stock
          </span>
        }
         {/* <span className="free-delivery">
           <RiCheckLine /> Free Delivery
          </span> */}
        {/* {selectedProduct?.trending && (
          <span className="trending">
            {selectedProduct?.trending ? "🔥 Trending" : ""}
          </span>
        )} */}

      </div>
      <div className="xperks">

      <div className="xperks-content">
          <p><BsBank2 /></p>
          <div>
            <p className="x-head">Payment options</p>
            <p> Enjoy multiple payment options through Flutterwave, and Paystack. Plus a convenient Pickup option.</p>
          </div>
        </div>
        <div className="xperks-content">
          <p><RiBusLine /></p>
          <div>
            <p className="x-head">24 - 48hours delivery</p>
            <p>Whether it's by road, airport, or straight to your doorstep, we've got you covered</p>
          </div>
        </div>
        <div className="xperks-content">
          <p><RiRepeat2Line /></p>
          <div>
            <p className="x-head">Return Policy</p>
            <p>  <Link to="/return-policy">Read our return policy</Link></p>
          </div>
        </div>
      </div>
      <div className="product-card-buttons-container">

      <button
          // disabled={cartLoading}
          onClick={sendWhatsAppMessage}
          className="add-to-wishlist-btn whatsapp"
        >
         Send us a message
        </button>
      {!!selectedProduct.is_stock && <button
          disabled={cartLoading}
          onClick={() =>{
            addToCartHandler(selectedProduct, 'buynow')
          }}
          className="add-to-cart-btn"
        >
          Buy Now
        </button>}
        {!!selectedProduct.is_stock && <button
          disabled={cartLoading}
          onClick={() => addToCartHandler(selectedProduct)}
          className="add-to-wishlist-btn"
        >
          {!isProductInCart(selectedProduct) ? "Add to cart" : "Go to cart"}
        </button>}
      
        {/* <button
          disabled={cartLoading}
          onClick={() => wishlistHandler({ productId: selectedProduct?._id, title: selectedProduct?.title })}
          className="add-to-wishlist-btn"
        >
          {!isProductInWishlist(selectedProduct?._id)
            ? "Add to wishlist"
            : "Remove from wishlist"}
        </button> */}
      </div>
      {selectedProduct.description && <div className="description-container x-content">
      <h3>Description</h3>
      <p >
       {selectedProduct?.description}
      </p>
      </div>}
      {/* {window.innerWidth <= 425 && <Reviews productId={selectedProduct?._id} comments={selectedProduct?.comments}/>} */}
      
    </div>
  );
};
